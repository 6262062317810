<template>
  <el-dialog
    title="分类"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :model="temp">
      <el-form-item label="所属用户" prop="gUserID">
        {{ temp.uName }}
      </el-form-item>
      <el-form-item label="反馈内容" prop="gContent">
        {{ temp.gContent }}
      </el-form-item>
      <el-form-item label="审核" prop="gChecked">
        {{ getState(temp.gChecked) }}
      </el-form-item>
      <el-form-item label="添加时间" prop="gAddTime">
        {{ temp.gAddTime }}
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="click_fn(1)">通过</el-button>
        <el-button @click="click_fn(2)">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
    },
    row_type(val) {
      Object.assign(this.temp, val);
    },
  },
  data() {
    return {
      dialogVisible: false,
      temp: {
        id: "",
        gUserID: "",
        gContent: "",
        gChecked: "",
        gIs_del: "",
        gAddTime: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    getState(val) {
      let rt = "待审";
      switch (parseInt(val)) {
        case 1:
          rt = "通过";
          break;
        case 2:
          rt = "取消";
          break;
      }
      return rt;
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    click_fn(val) {
      this.$api.gbooks_update({ id: this.row_type.id, st: val }).then((res) => {
        // this.getList();
        this.$notify({
          title: "温馨提示",
          message: res.data.msg,
          type: "success",
          duration: 2000,
        });
        this.close();
        this.update();
      });
    },
    save() {
      // console.log(this.temp);
      // this.$refs["dataForm"].validate((valid) => {
      //   if (valid) {
      //     // this.temp.id = 0; // mock a id
      //     this.$api.gbooks_save(this.temp).then((res) => {
      //       this.close();
      //       this.update();
      //       this.$notify({
      //         title: "温馨提示",
      //         message: res.data.msg,
      //         type: "success",
      //         duration: 2000,
      //       });
      //     });
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped></style>
